import {FC} from 'react';

import {CartesMenusSection} from '@src/components/cartes_menus_section';
import {ContactAccesSection} from '@src/components/contact_acces_section';
import {OutsidePhotoSection} from '@src/components/outside_photo_section';
import {PlatePhotosSection} from '@src/components/plate_photos_section';
import {RestaurantPresentationSection} from '@src/components/restaurant_presentation_section';
import {TopSection} from '@src/components/top_section';

interface MainPageProps {}

export const MainPage: FC<MainPageProps> = () => {
  return (
    <>
      <TopSection />
      <RestaurantPresentationSection />
      <PlatePhotosSection />
      <CartesMenusSection />
      <OutsidePhotoSection />
      <ContactAccesSection />
    </>
  );
};

MainPage.displayName = 'MainPage';
